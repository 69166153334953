// In src/components/Section.js
import React from "react";
import Slider from "react-slick";
import "./Content.css";
import "../styles/colors.css";
import imageIntro from "../assets/intro_1920.webp";
import imageAppFaecher from "../assets/app_faecher.webp";
import imageAppStore from "../assets/apple_app_store_black_white.png";
import imagePlayStore from "../assets/google_play_black_white.png";
import imageAluwissen1 from "../assets/image_aluwissen_detail_1.webp";
import imageAluwissen2 from "../assets/image_aluwissen_uebersicht_1.webp";
import imageHome1 from "../assets/image_home_1.webp";
import imageProduktBerater1 from "../assets/image_produktberater_1.webp";
import imageProduktBerater2 from "../assets/image_produktberater_2.webp";
import imageSonderposten1 from "../assets/image_sonderposten_1.webp";
import imageSonderposten2 from "../assets/image_sonderposten_2.webp";
import qrApp from "../assets/qr_app-gleich-de.png";
import getTextByLocale from "../utils/localizations";
import ResponsiveImage from "./ResponsiveImage";

const Content = ({ locale, deviceType }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  console.log(deviceType);

  return (
    <>
      <div
        className="container-fluid"
        style={{ margin: 0, marginTop: 60, padding: 0 }}
      >
        <ResponsiveImage src={imageIntro} alt="" />
      </div>
      <div className="container">
        <div className="row align-items-center section">
          <div className="col-md-6">
            <h3 className="headline-3">
              {getTextByLocale(
                locale,
                "Aluminiumwissen für die Hosentasche",
                "Aluminum knowledge for your pocket"
              )}
            </h3>
            <h1 className="headline-1">GLEICH Aluminium App</h1>
            <p className="copy">
              {getTextByLocale(
                locale,
                "Ob Profi oder Einsteiger, die GLEICH Aluminium App bietet Ihnen alles, was Sie brauchen, um das Beste aus Aluminium herauszuholen. Unsere App ist Ihr persönlicher Begleiter für Aluminiumprodukte und Services, immer griffbereit und einfach zu bedienen. Entdecken Sie unsere innovativen Features und profitieren Sie von exklusiven Angeboten!",
                "Whether you are a professional or a beginner, the GLEICH aluminum app offers you everything you need to get the best out of aluminum. Our app is your personal companion for aluminum products and services, always at hand and easy to use. Discover our innovative features and benefit from exclusive offers!"
              )}
            </p>
            <div
              className="store-button-wrap"
              style={{
                marginTop: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {deviceType === "iOS" && (
                <a
                  href="https://app.gleich.de"
                  rel="noreferrer"
                  target="_blank"
                  id="apple-store-1"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        width: 250,
                        marginLeft: 15,
                        marginRight: 15,
                        marginBottom: 20,
                      }}
                    >
                      <ResponsiveImage src={imageAppStore} alt="App Store" />
                    </div>
                  </div>
                </a>
              )}
              {deviceType === "Android" && (
                <a
                  href="https://app.gleich.de"
                  rel="noreferrer"
                  target="_blank"
                  id="play-store-1"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        width: 250,
                        marginLeft: 15,
                        marginRight: 15,
                        marginBottom: 20,
                      }}
                    >
                      <ResponsiveImage src={imagePlayStore} alt="Play Store" />
                    </div>
                  </div>
                </a>
              )}
              {deviceType === "Web" && (
                <div>
                  <div
                    style={{
                      width: 200,
                      marginLeft: 15,
                      marginRight: 15,
                      marginBottom: 20,
                    }}
                  >
                    <ResponsiveImage src={qrApp} alt="QR Store" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-image">
              <ResponsiveImage src={imageAppFaecher} alt="App Fächer" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row section bgBlue">
          <div className="col-12">
            <div className="container content-container">
              <div className="row">
                <div className="col-md-12">
                  <Slider {...sliderSettings}>
                    <div className="slider-image-wrapper">
                      <div className="slider-image">
                        <ResponsiveImage src={imageHome1} alt="Home Bild 1" />
                      </div>
                    </div>
                    <div className="slider-image-wrapper">
                      <div className="slider-image">
                        <ResponsiveImage
                          src={imageAluwissen1}
                          alt="Alu-Wissen Bild 1"
                        />
                      </div>
                    </div>
                    <div className="slider-image-wrapper">
                      <div className="slider-image">
                        <ResponsiveImage
                          src={imageAluwissen2}
                          alt="Alu-Wissen Bild 2"
                        />
                      </div>
                    </div>
                    <div className="slider-image-wrapper">
                      <div className="slider-image">
                        <ResponsiveImage
                          src={imageSonderposten1}
                          alt="Sonderposten Bild 1"
                        />
                      </div>
                    </div>
                    <div className="slider-image-wrapper">
                      <div className="slider-image">
                        <ResponsiveImage
                          src={imageSonderposten2}
                          alt="Sonderposten Bild 2"
                        />
                      </div>
                    </div>
                    <div className="slider-image-wrapper">
                      <div className="slider-image">
                        <ResponsiveImage
                          src={imageProduktBerater1}
                          alt="Produktberater Bild 1"
                        />
                      </div>
                    </div>
                    <div className="slider-image-wrapper">
                      <div className="slider-image">
                        <ResponsiveImage
                          src={imageProduktBerater2}
                          alt="Produktberater Bild 2"
                        />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: 25 }}>
        <div className="row section">
          <h2 className=" headline-2 text-uppercase special-headline">
            {getTextByLocale(
              locale,
              "Das bietet die GLEICH Aluminium APP",
              "What the GLEICH aluminum APP offers"
            )}
          </h2>
        </div>
        <div className="row align-items-center section">
          <div className="col-md-6">
            <h3 className="headline-3">
              {getTextByLocale(
                locale,
                "Aluminiumhandbuch – Ihr Wissen auf Abruf",
                "Aluminum handbook - your knowledge on demand"
              )}
            </h3>
            <p className="copy">
              {getTextByLocale(
                locale,
                "Entdecken Sie unser gebündeltes Expertenwissen! Unser Aluminiumhandbuch bietet Ihnen detaillierte Informationen über die Eigenschaften, Anwendungen und Vorteile von Aluminium. Egal, ob sie technische Spezifikationen benötigen oder sich in die Welt des Aluminiums vertiefen möchten – hier finden Sie alles, was Sie wissen müssen.",
                "Discover our bundled expert knowledge! Our aluminum handbook provides you with detailed information on the properties, applications and benefits of aluminum. Whether you need technical specifications or want to delve deeper into the world of aluminum, you'll find everything you need to know here."
              )}
            </p>
          </div>
          <div className="col-md-6 text-center">
            <div className="col-image col-image-app-view">
              <ResponsiveImage src={imageAluwissen1} alt="Alu-Wissen Bild 1" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center section reverse-order-mobile">
          <div className="col-md-6 text-center">
            <div className="col-image col-image-app-view">
              <ResponsiveImage
                src={imageProduktBerater1}
                alt="Produktberater Bild 1"
              />
            </div>
          </div>
          <div className="col-md-6">
            <h3 className="headline-3">
              {getTextByLocale(
                locale,
                "Digitaler Produktberater – Finden Sie das perfekte Produkt für Ihr Projekt",
                "Digital Product Advisor - Find the perfect product for your project"
              )}
            </h3>
            <p className="copy">
              {getTextByLocale(
                locale,
                "Mit unserem digitalen Produktberater können Sie mit wenigen Klicks herausfinden, welches unserer Aluminiumprodukte optimal für Ihren Anwendungszweck geeignet ist. Egal ob für den Maschinenbau, den Formenbau oder die Meedizintechnik – Sie erhalten maßgeschneiderte Empfehlungen, die zu Ihren Anforderungen passen.",
                "With our digital product advisor, you can find out which of our aluminum products is ideal for your application with just a few clicks. Whether for mechanical engineering, mold making or medical technology - you will receive tailor-made recommendations that match your requirements."
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center section">
          <div className="col-md-6">
            <h3 className="headline-3">
              {getTextByLocale(
                locale,
                "Exklusive Aluminiumangebote – Profitieren Sie von Sonderposten",
                "Exclusive aluminum offers - Benefit from special items"
              )}
            </h3>
            <p className="copy">
              {getTextByLocale(
                locale,
                "Als Nutzer der GLEICH Aluminium App erhalten Sie exklusiven Zugriff auf Sonderposten und Restbestände, die nur über die App erhältlich sind. Sichern Sie sich hochwertige Aluminiumprodukte zu Sonderkonditionen – und das bequem von Ihrem Smartphone aus. Einfach stöbern, anfragen und profitieren!",
                "As a user of the GLEICH aluminum app, you get exclusive access to special items and remaining stock that are only available via the app. Secure high-quality aluminum products at special conditions - all from the comfort of your smartphone. Simply browse, request and benefit!"
              )}
            </p>
          </div>
          <div className="col-md-6 text-center">
            <div className="col-image col-image-app-view">
              <ResponsiveImage
                src={imageSonderposten1}
                alt="Sonderposten Bild 1"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: 50 }}>
        <div className="row section border-seperator">
          <h3 className="headline-3 text-center">
            {getTextByLocale(
              locale,
              "Aluminium smarter erleben – mit der GLEICH Aluminium App!",
              "Experience aluminum smarter - with the GLEICH aluminum app!"
            )}
          </h3>
        </div>
        <div className="row align-items-center section">
          <div className="col-md-6 text-center">
            <div
              className="store-button-wrap"
              style={{
                marginTop: 50,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {deviceType === "iOS" && (
                <a
                  href="https://app.gleich.de"
                  rel="noreferrer"
                  target="_blank"
                  id="apple-store-2"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        width: 250,
                        marginLeft: 15,
                        marginRight: 15,
                        marginBottom: 20,
                      }}
                    >
                      <ResponsiveImage src={imageAppStore} alt="App Store" />
                    </div>
                  </div>
                </a>
              )}
              {deviceType === "Android" && (
                <a
                  href="https://app.gleich.de"
                  rel="noreferrer"
                  target="_blank"
                  id="play-store-2"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        width: 250,
                        marginLeft: 15,
                        marginRight: 15,
                        marginBottom: 20,
                      }}
                    >
                      <ResponsiveImage src={imagePlayStore} alt="Play Store" />
                    </div>
                  </div>
                </a>
              )}
              {deviceType === "Web" && (
                <div className="qr-wrapper">
                  <div
                    style={{
                      width: 200,
                      marginLeft: 15,
                      marginRight: 15,
                      marginBottom: 20,
                    }}
                  >
                    <ResponsiveImage src={qrApp} alt="QR Store" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <h3 className="headline-3">
              {getTextByLocale(
                locale,
                "Jetzt herunterladen und durchstarten!",
                "Download now and get started!"
              )}
            </h3>
            <p className="copy">
              {getTextByLocale(
                locale,
                "Verpassen Sie nicht die Gelegenheit, das volle Potenzial von Aluminium für Ihre Projekte zu nutzen. Laden Sie die GLEICH Aluminium App noch heute herunter und entdecken Sie unsere umfassenden Lösungen – kostenlos und jederzeit verfügbar.",
                "Don't miss the opportunity to use the full potential of aluminum for your projects. Download the GLEICH aluminum app today and discover our comprehensive solutions - free of charge and available at any time."
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center section">
          <div className="col-md-12">
            <h3 className="headline-3">
              {getTextByLocale(
                locale,
                "Über GLEICH Aluminium",
                "About GLEICH Aluminum"
              )}
            </h3>
            <p className="copy">
              {getTextByLocale(
                locale,
                "GLEICH Aluminium ist Ihr zuverlässiger Partner in der Welt des Aluminiums. Mit jahrzehntelanger Erfahrung und innovativen Lösungen setzen wir Maßstäbe in der Aluminiumbranche. Die GLEICH Aluminium App bringt unser Wissen und unsere Produkte direkt zu Ihnen – einfach, schnell und digital.",
                "GLEICH Aluminium is your reliable partner in the world of aluminum. With decades of experience and innovative solutions, we set standards in the aluminum industry. The GLEICH Aluminium app brings our knowledge and our products directly to you - simply, quickly and digitally."
              )}
            </p>
          </div>
        </div>
        <div className="row section">
          <div className="col-md-12">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "50px",
                flexWrap: "wrap", // Ermöglicht das Umbruchverhalten
              }}
            >
              <div>
                <a
                  href="https://www.gleich-aluminium-shop.de"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-red"
                  id="button-gleich-aluminium-shop-de"
                >
                  Online Shop
                </a>
              </div>
              <div>
                <a
                  href="https://www.gleich-cnc.de"
                  rel="noreferrer"
                  target="_blank"
                  className="button button-blue"
                  id="button-gleich-cnc-de"
                >
                  {getTextByLocale(locale, "Zeichnungsteile", "Drawing parts")}
                </a>
              </div>
              <div>
                <a
                  href={
                    locale === "de"
                      ? "https://www.gleich.de"
                      : "https://www.gleich.de/en"
                  }
                  rel="noreferrer"
                  target="_blank"
                  className="button button-red"
                  id="button-gleich-de"
                >
                  GLEICH Aluminium
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
