// Setzt die Zustimmung und sendet das Event an den dataLayer
export const handleConsentAccept = () => {
  localStorage.setItem("consentGiven", "true");
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "gtm.consent",
    analytics_storage: "granted",
  });
};

// Widerruft die Zustimmung, entfernt den dataLayer-Eintrag und löscht Cookies
export const handleConsentDecline = () => {
  localStorage.removeItem("consentGiven");
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "gtm.consent",
    analytics_storage: "denied",
  });
  deleteGtmCookies();
};

// Löscht alle relevanten GTM- und Google Analytics-Cookies
const deleteGtmCookies = () => {
  const cookies = document.cookie.split("; ");
  cookies.forEach((cookie) => {
    const cookieName = cookie.split("=")[0];
    if (
      cookieName.startsWith("_ga") || // Google Analytics Cookie
      cookieName.startsWith("_gid") ||
      cookieName.startsWith("_gat") ||
      cookieName === "_gcl_au" // GTM Cookie
    ) {
      document.cookie = `${cookieName}=; Max-Age=0; path=/; domain=${window.location.hostname}`;
    }
  });
};

// Prüft den initialen Consent-Status beim Laden der Seite
export const loadInitialConsent = () => {
  const consent = localStorage.getItem("consentGiven");
  if (consent === "true") {
    handleConsentAccept();
    return true;
  } else if (consent === "false") {
    handleConsentDecline();
    return false;
  }
  return null;
};
