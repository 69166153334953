// In src/pages/ImpressumEn.js
import React from "react";

const ImpressumEn = () => {
  return (
    <div className="page">
      <h1>Legal Notice</h1>
      <p>
        <b>GLEICH Aluminium Service-Center GmbH & Co. KG</b>
        <br />
        Hans-Stockmar-Str. 3 <br />
        24568 Kaltenkirchen, Germany
      </p>
      <p>
        Phone:{" "}
        <a href="tel:+4941915070232" id="impressum-link-tel">
          +49 (0) 4191 5070 - 232
        </a>
        <br />
        Fax:{" "}
        <a href="tel:+4941915070298" id="impressum-link-fax">
          +49 (0) 4191 5070 298
        </a>
        <br />
        Website:{" "}
        <a
          href="https://www.gleich.de"
          target="_blank"
          rel="noreferrer"
          id="impressum-link-gleich-de"
        >
          www.gleich.de
        </a>
        <br />
        Email:{" "}
        <a href="mailto:shop@gleich.de" id="impressum-link-email">
          shop@gleich.de
        </a>
      </p>
      <p>Managing Directors: Roland Gleich, Sebastian Ricken</p>
      <p>
        Responsible for content: Sebastian Ricken <br />
        Jurisdiction: District Court of Kiel <br />
        Commercial Register No.: HRA 5814 KI HRB No.: HRB 9198 KI <br />
        Tax ID: 1128069802 VAT ID: DE 256068870
      </p>
      <p>
        Our customer service team is available for questions, complaints, and
        claims at Phone: +49 (0) 4191 5070 232 and via email at shop@gleich.de.
        You can also use the contact form on our website for inquiries.
      </p>
      <p>
        <b>Copyright and Trademark Law</b>
        <p>
          The author endeavors to respect the copyrights of the images,
          graphics, sound documents, video sequences, and texts used in all
          publications, to use images, graphics, sound documents, video
          sequences, and texts created by themselves, or to resort to
          license-free graphics, sound documents, video sequences, and texts.
          All brands and trademarks mentioned within the website, and possibly
          protected by third parties, are subject to the provisions of the
          applicable trademark law and ownership rights of the respective
          registered owner. The mere mention of trademarks does not imply that
          they are not protected by third-party rights. The copyright for
          published objects created by the author remains solely with the author
          of the pages. Any duplication or use of such graphics, sound
          documents, video sequences, and texts in other electronic or printed
          publications is not permitted without the express consent of the
          author.
        </p>
      </p>
    </div>
  );
};

export default ImpressumEn;
