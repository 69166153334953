// In src/pages/DatenschutzEn.js
import React from "react";

const DatenschutzEn = () => {
  return (
    <div className="page">
      <h1>Privacy Policy</h1>

      <p>
        GLEICH Aluminium GmbH takes the protection of your personal data very
        seriously. We treat your personal data confidentially and in accordance
        with the statutory data protection regulations and this privacy policy.
      </p>

      <h2>1. Controller</h2>
      <p>
        <strong>GLEICH Aluminium GmbH</strong>
        <br />
        Kirchhoffstr. 2<br />
        D-24568 Kaltenkirchen
        <br />
        Germany
        <br />
        Tel: (+49) 4191-5070 -0
        <br />
        Fax: (+49) 4191-5070 -500
        <br />
        Email: <a href="mailto:info@gleich.de">info@gleich.de</a>
        <br />
        Website:{" "}
        <a
          href="https://www.gleich.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.gleich.de
        </a>
      </p>

      <h2>
        2. Collection and Storage of Personal Data and the Type and Purpose of
        Their Use
      </h2>
      <p>
        We collect and store personal data that is directly provided to us, for
        example, through inquiries via our contact form or email communication.
      </p>

      <h2>3. Data Sharing</h2>
      <p>
        Your personal data will not be shared with third parties for purposes
        other than those listed below.
      </p>

      <h2>4. Google Analytics</h2>
      <p>
        This website uses Google Analytics, a web analytics service provided by
        Google Inc. ("Google"). Google Analytics uses "cookies," which are text
        files placed on your computer, to help analyze how you use the website.
        The information generated by the cookie about your use of this website
        is generally transmitted to a Google server in the USA and stored there.
      </p>
      <p>
        We have activated IP anonymization on this website. As a result, your IP
        address is shortened by Google within member states of the European
        Union or in other contracting states of the Agreement on the European
        Economic Area before transmission. Only in exceptional cases will the
        full IP address be transmitted to a Google server in the USA and
        shortened there. On behalf of the operator of this website, Google will
        use this information to evaluate your use of the website, compile
        reports on website activity, and provide other services related to
        website and internet usage to the website operator.
      </p>
      <p>
        You can prevent the storage of cookies by selecting the appropriate
        settings on your browser; however, we point out that in this case, you
        may not be able to use the full functionality of this website. You can
        also prevent the collection of data generated by the cookie and related
        to your use of the website (including your IP address) to Google and the
        processing of this data by Google by downloading and installing the
        browser plugin available at the following link:{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout?hl=en
        </a>
        .
      </p>
      <p>
        Further information on terms of use and data protection can be found at{" "}
        <a
          href="https://www.google.com/analytics/terms/en.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.com/analytics/terms/en.html
        </a>{" "}
        and{" "}
        <a
          href="https://policies.google.com/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/?hl=en
        </a>
        .
      </p>

      <h2>5. Data Subject Rights</h2>
      <p>
        You have the right to request information about the processing of your
        personal data at any time, as well as the right to rectification,
        deletion, restriction of processing, objection to processing, and the
        right to data portability.
      </p>

      <h2>6. Updates and Changes to This Privacy Policy</h2>
      <p>
        This privacy policy is currently valid and has the status of October
        2024. Due to the further development of our website and offers or due to
        changed legal requirements, it may be necessary to amend this privacy
        policy.
      </p>
    </div>
  );
};

export default DatenschutzEn;
