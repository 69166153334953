// In src/components/Header.js
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Header.css";
import logo from "../assets/gleich_logo_g_only.png"; // Logo importieren
import getTextByLocale from "../utils/localizations";

const Header = ({ locale = "en", onLanguageChange }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <header className="header">
      <div className="header-bar">
        <a href="/">
          <img src={logo} alt="Gleich Logo" className="header-logo" />
        </a>
        <span className="locale" onClick={toggleModal}>
          {locale ? locale.toUpperCase() : "EN"}
        </span>
      </div>

      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {getTextByLocale(locale, "Sprache auswählen", "Select language")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="language-switcher-button-wrapper">
            <Button
              variant="secondary"
              onClick={() => {
                onLanguageChange("de");
                toggleModal();
              }}
            >
              DE
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                onLanguageChange("en");
                toggleModal();
              }}
              style={{ marginLeft: "10px" }}
            >
              EN
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default Header;
