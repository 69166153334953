// In src/pages/DatenschutzDe.js
import React from "react";

const DatenschutzDe = ({ updateConsent, consentGiven }) => {
  // Funktion zum Aktualisieren des Consent-Status und zum Neuladen der Seite
  const handleConsentChange = (consent) => {
    updateConsent(consent);
    window.location.reload(); // Seite neu laden, um Änderungen anzuwenden
  };
  return (
    <div className="page">
      <h1>Datenschutzerklärung</h1>

      <p>
        Die GLEICH Aluminium GmbH nimmt den Schutz Ihrer persönlichen Daten sehr
        ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>

      <h2>1. Verantwortlicher</h2>
      <p>
        <strong>GLEICH Aluminium GmbH</strong>
        <br />
        Kirchhoffstr. 2<br />
        D-24568 Kaltenkirchen
        <br />
        Deutschland
        <br />
        Tel: (+49) 4191-5070 -0
        <br />
        Fax: (+49) 4191-5070 -500
        <br />
        E-Mail: <a href="mailto:info@gleich.de">info@gleich.de</a>
        <br />
        Internet:{" "}
        <a
          href="https://www.gleich.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.gleich.de
        </a>
      </p>

      <h2>
        2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck
        der Verwendung
      </h2>
      <p>
        Wir erheben und speichern personenbezogene Daten, die uns direkt
        übermittelt werden, z.B. durch Anfragen über unser Kontaktformular oder
        E-Mail-Kommunikation.
      </p>

      <h2>3. Weitergabe von Daten</h2>
      <p>
        Eine Weitergabe Ihrer personenbezogenen Daten an Dritte zu anderen als
        den im Folgenden aufgeführten Zwecken findet nicht statt.
      </p>

      <h2>4. Google Analytics</h2>
      <p>
        Diese Website benutzt Google Analytics, einen Webanalysedienst der
        Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
        Textdateien, die auf Ihrem Computer gespeichert werden und die eine
        Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
        Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden
        in der Regel an einen Server von Google in den USA übertragen und dort
        gespeichert.
      </p>
      <p>
        Wir haben die IP-Anonymisierung auf dieser Website aktiviert. Dadurch
        wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
        Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
        den Europäischen Wirtschaftsraum vor der Übermittlung gekürzt. Nur in
        Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
        den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
        Website wird Google diese Informationen benutzen, um Ihre Nutzung der
        Website auszuwerten, um Reports über die Websiteaktivitäten
        zusammenzustellen und um weitere mit der Websitenutzung und der
        Internetnutzung verbundene Dienstleistungen gegenüber dem
        Websitebetreiber zu erbringen.
      </p>
      <p>
        Sie können die Speicherung der Cookies durch eine entsprechende
        Einstellung Ihrer Browser-Software verhindern; wir weisen jedoch darauf
        hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
        dieser Website vollumfänglich werden nutzen können. Sie können darüber
        hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung
        der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
        Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter
        dem folgenden Link verfügbare Browser-Plugin herunterladen und
        installieren:{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>
        .
      </p>
      <p>
        Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
        unter{" "}
        <a
          href="https://www.google.com/analytics/terms/de.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.com/analytics/terms/de.html
        </a>{" "}
        bzw. unter{" "}
        <a
          href="https://policies.google.com/?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/?hl=de
        </a>
        .
      </p>
      {/* Anzeige des aktuellen Consent-Status */}
      <p id="consent">
        <strong>Aktueller Status:</strong>{" "}
        {consentGiven ? "Akzeptiert" : "Abgelehnt"}
      </p>

      {/* Button zum Ändern des Consent-Status */}
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        {consentGiven ? (
          <button
            onClick={() => handleConsentChange(false)}
            style={buttonStyle}
          >
            Zustimmung widerrufen
          </button>
        ) : (
          <button onClick={() => handleConsentChange(true)} style={buttonStyle}>
            Zustimmung erteilen
          </button>
        )}
      </div>

      <h2>5. Betroffenenrechte</h2>
      <p>
        Sie haben das Recht, jederzeit Auskunft über die Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen, ebenso wie auf Berichtigung,
        Löschung, Einschränkung der Verarbeitung und Widerspruch gegen die
        Verarbeitung sowie das Recht auf Datenübertragbarkeit.
      </p>

      <h2>6. Aktualität und Änderung dieser Datenschutzerklärung</h2>
      <p>
        Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Oktober
        2024. Durch die Weiterentwicklung unserer Website und Angebote darüber
        oder aufgrund geänderter gesetzlicher Vorgaben kann es notwendig werden,
        diese Datenschutzerklärung zu ändern.
      </p>
    </div>
  );
};

// Stil für die Buttons
const buttonStyle = {
  padding: "10px 20px",
  marginRight: "10px",
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

export default DatenschutzDe;
