// In src/pages/ImpressumDe.js
import React from "react";

const ImpressumDe = () => {
  return (
    <div className="page">
      <h1>Impressum</h1>
      <p>
        <b>GLEICH Aluminium Service-Center GmbH & Co. KG</b>
        <br />
        Hans-Stockmar-Str. 3 <br />
        24568 Kaltenkirchen Deutschland
      </p>
      <p>
        Telefon:{" "}
        <a href="tel:+4941915070232" id="impressum-link-tel">
          +49 (0) 4191 5070 - 232
        </a>{" "}
        <br />
        Fax:{" "}
        <a href="tel:+4941915070298" id="impressum-link-fax">
          +49 (0) 4191 5070 298
        </a>{" "}
        <br />
        Web:{" "}
        <a
          href="https://www.gleich.de"
          target="_blank"
          rel="noreferrer"
          id="impressum-link-gleich-de"
        >
          www.gleich.de
        </a>{" "}
        <br />
        E-Mail:{" "}
        <a href="mailto:shop@gleich.de" id="impressum-link-email">
          shop@gleich.de
        </a>
      </p>
      <p>Geschäftsführer: Roland Gleich, Sebastian Ricken</p>
      <p>
        Verantwortlich für den Inhalt: Sebastian Ricken Zuständiges Gericht:
        <br />
        Amtsgericht Kiel HRA 5814 KI HRB Nummer: HRB 9198 KI Steuernummer:
        <br />
        1128069802 USt.Id.: DE 256068870
      </p>
      <p>
        Sie erreichen unseren Kundendienst für Fragen Reklamationen und
        Beanstandungen unter Telefon: +49 (0) 4191 5070 232 sowie per Email
        unter shop@gleich.de. Für Anfragen können Sie auch das Kontaktformular
        auf unserer Internetseite nutzen.
      </p>
      <p>
        <b>Urheber- und Kennzeichenrecht</b>
        <p>
          Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
          verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte
          zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente,
          Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
          Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb
          des Internetangebotes genannten und ggf. durch Dritte geschützten
          Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen
          des jeweils gültigen Kennzeichenrechts und den Besitzrechten der
          jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen
          Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht
          durch Rechte Dritter geschützt sind! Das Copyright für
          veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim
          Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher
          Grafiken, Tondokumente, Videosequenzen und Texte in anderen
          elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
          Zustimmung des Autors nicht gestattet.
        </p>
      </p>
    </div>
  );
};

export default ImpressumDe;
