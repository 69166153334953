import React, { useState, useEffect, useRef } from "react";

const ResponsiveImage = ({ src, alt, style }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      const aspectRatio = img.width / img.height;

      // Initiale Breite des Containers erfassen und Höhe berechnen
      const updateDimensions = () => {
        const containerWidth = containerRef.current.offsetWidth;
        const calculatedHeight = containerWidth / aspectRatio;

        setDimensions({
          width: containerWidth,
          height: calculatedHeight,
        });
      };

      updateDimensions(); // Erste Berechnung durchführen

      // Resize Observer für dynamische Breitenänderungen des Containers
      const resizeObserver = new ResizeObserver(updateDimensions);
      resizeObserver.observe(containerRef.current);

      return () => resizeObserver.disconnect(); // Cleanup
    };
  }, [src]);

  return (
    <div ref={containerRef} style={{ width: "100%" }}>
      <img
        src={src}
        alt={alt}
        width={dimensions.width}
        height={dimensions.height}
        style={{ maxWidth: "100%", height: "auto" }}
      />
    </div>
  );
};

export default ResponsiveImage;
