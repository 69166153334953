// In src/components/Footer.js
import React from "react";
import "./Footer.css";

const Footer = ({ locale }) => {
  return (
    <footer className="footer">
      <div className="footer-bar">
        <a href="/impressum" className="footer-link">
          {locale === "de" ? "Impressum" : "Imprint"}
        </a>
        <a href="/datenschutz" className="footer-link">
          {locale === "de" ? "Datenschutz" : "Data protection"}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
