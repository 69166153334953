// In src/components/ConsentBanner.js
import React from "react";
import getTextByLocale from "../utils/localizations";
import "./ConsentBanner.css";

const ConsentBanner = ({ locale, onAccept, onDecline }) => {
  return (
    <div className="consent-banner">
      <div className="consent-text">
        <p>
          {getTextByLocale(
            locale,
            "Wir verwenden Google Analytics, um die Benutzererfahrung zu verbessern. Sie können die Nutzung akzeptieren oder ablehnen. Ohne Ihre Zustimmung werden keine Cookies gespeichert. Sie können Ihre Entscheidung jederzeit im Bereich Datenschutz ändern.",
            "We use Google Analytics to improve user experience. You can accept or decline its usage. No cookies will be stored without your consent. You can change your decision at any time in the privacy section."
          )}
        </p>
      </div>
      <div className="consent-buttons">
        <button onClick={onAccept}>
          {getTextByLocale(locale, "Akzeptieren", "Accept")}
        </button>
        <button onClick={onDecline}>
          {getTextByLocale(locale, "Ablehnen", "Decline")}
        </button>
      </div>
    </div>
  );
};

export default ConsentBanner;
