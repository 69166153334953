import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Content from "./components/Content";
import ImpressumDe from "./pages/ImpressumDe";
import ImpressumEn from "./pages/ImpressumEn";
import DatenschutzDe from "./pages/DatenschutzDe";
import DatenschutzEn from "./pages/DatenschutzEn";
import { detectDeviceType } from "./utils/deviceDetection";
import ConsentBanner from "./components/ConsentBanner";
import TagManager from "react-gtm-module";
import {
  handleConsentAccept,
  handleConsentDecline,
  loadInitialConsent,
} from "./utils/consentHelper";
import "./App.css";

const App = () => {
  const [locale, setLocale] = useState("en");
  const [deviceType, setDeviceType] = useState("Web");
  const [consentGiven, setConsentGiven] = useState(null);

  useEffect(() => {
    // Laden der initialen Sprache und des Consent-Status
    const savedLocale =
      localStorage.getItem("locale") || navigator.language.slice(0, 2);
    setLocale(savedLocale === "de" ? "de" : "en");

    setDeviceType(detectDeviceType());

    const initialConsent = loadInitialConsent();
    setConsentGiven(initialConsent);

    if (initialConsent) {
      // Initialisiert GTM bei Zustimmung
      initializeGTM();
    }
  }, []);

  const handleLanguageChange = (newLocale) => {
    setLocale(newLocale);
    localStorage.setItem("locale", newLocale);
  };

  const initializeGTM = () => {
    TagManager.initialize({ gtmId: "GTM-TF4RSQRN" }); // GTM-ID einfügen
  };

  const removeGTM = () => {
    const gtmScript = document.querySelector(
      `script[src*="googletagmanager.com/gtm.js?id=GTM-TF4RSQRN"]`
    );
    if (gtmScript) gtmScript.remove();

    const gtmNoscript = document.querySelector(
      `iframe[src*="googletagmanager.com/ns.html?id=GTM-TF4RSQRN"]`
    );
    if (gtmNoscript) gtmNoscript.remove();
  };

  // Consent-Zustimmung ändern
  const updateConsent = (consent) => {
    if (consent) {
      handleConsentAccept();
      setConsentGiven(true);
      initializeGTM();
    } else {
      handleConsentDecline();
      setConsentGiven(false);
    }
  };

  // Entfernt GTM, wenn consentGiven auf false gesetzt wird
  useEffect(() => {
    if (consentGiven === false) {
      removeGTM();
    }
  }, [consentGiven]);

  return (
    <Router>
      <div className="App">
        <Header
          locale={locale}
          deviceType={deviceType}
          onLanguageChange={handleLanguageChange}
        />

        <Routes>
          <Route
            path="/"
            element={<Content locale={locale} deviceType={deviceType} />}
          />
          {locale === "de" ? (
            <>
              <Route path="/impressum" element={<ImpressumDe />} />
              <Route
                path="/datenschutz"
                element={
                  <DatenschutzDe
                    updateConsent={updateConsent}
                    consentGiven={consentGiven}
                  />
                }
              />
            </>
          ) : (
            <>
              <Route path="/impressum" element={<ImpressumEn />} />
              <Route
                path="/datenschutz"
                element={
                  <DatenschutzEn
                    updateConsent={updateConsent}
                    consentGiven={consentGiven}
                  />
                }
              />
            </>
          )}
        </Routes>
        <Footer locale={locale} deviceType={deviceType} />

        {consentGiven === null && (
          <ConsentBanner
            locale={locale}
            onAccept={() => updateConsent(true)}
            onDecline={() => updateConsent(false)}
          />
        )}
      </div>
    </Router>
  );
};

export default App;
